import React from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'

const Hero = () => {
  const { t } = useTranslation()

  return (
    <section className="hero ">
      <div className="hero__container">
        <div className="hero__container--text">
          <h1
            className="hero__title"
            dangerouslySetInnerHTML={{
              __html: t('page_home_section_hero_title'),
            }}
          />
          <p className="hero__subtitle  h5-style secondary font-medium">
            {t('page_home_section_hero_subtitle')}
          </p>
          <div className="hero__btn">
            <a href="#features" className="btn btn-primary">
              {t('page_home_section_hero_btn')}
            </a>
          </div>
        </div>
        <div className="hero__container--image">
          <StaticImage src="../../assets/images/heroHome.png" alt="" />
        </div>
      </div>
    </section>
  )
}

export default Hero
